<div>
    <div class="row ">
        <div class="col-12 col-md-5 mb-5 mt-5 d-flex justify-content-center align-items-center">
            <img src="assets/logo/mxss%20logo-1.svg" alt="mxss-logo">
        </div>
        <div class="col-12 col-md-7 bars d-flex justify-content-center align-items-center">
            <h5><span>Soluciones tecnológicas a medida para tu empresa.</span><br> Resolvemos tus necesidades apoyándonos de las nuevas<br> tecnologías, adaptadas a tu negocio para potenciarlo al máximo.<br> Estamos comprometidos en encontrar no solo la mejor
                solución,
                <br> sino que tenga bases firmes para crecer y adaptarse a largo plazo. <br><br><br>
                <span>Tú pones el problema, nosotros la solución.<br>
        ¿Tu negocio está listo para dar el siguiente paso?</span></h5>
        </div>
    </div>
    <div class="row" style="background-color: #C9133C">
        <div class="col-12 d-flex justify-content-center align-items-center pt-3 pb-3">
            <h5>SERVICIOS: Conoce todas las soluciones para tu negocio.</h5>
        </div>
        <div class="col-md-3 flex-column center " routerLink="/consulting" style="cursor: pointer">
            <div class="circle">
                <img class="icon" src="assets/icons/Grupo%2060.svg" alt="">
                <div class="circle-hide">
                    <p class="show-m">VER MÁS</p>
                </div>
            </div>
            <div class="pt-4">
                <h5 class="text-center">Consultoría y <br> asesoramiento</h5>
            </div>
        </div>
        <div class="col-md-3 flex-column center" routerLink="/software" style="cursor: pointer">
            <div class="circle">
                <img class="icon" src="assets/icons/Grupo%2049.svg" alt="">
                <div class="circle-hide">
                    <p class="show-m">VER MÁS</p>
                </div>
            </div>
            <div class="pt-4">
                <h5 class="text-center">Desarrollo <br> de Software</h5>
            </div>
        </div>
        <div class="col-md-3 flex-column center" routerLink="/app" style="cursor: pointer">
            <div class="circle">
                <img class="icon" src="assets/icons/Grupo%2052.svg" alt="">
                <div class="circle-hide">
                    <p class="show-m">VER MÁS</p>
                </div>
            </div>
            <div class="pt-4">
                <h5 class="text-center">Aplicaciones <br> móviles</h5>
            </div>
        </div>
        <div class="col-md-3 flex-column center" routerLink="/web" style="cursor: pointer">
            <div class="circle">
                <img class="icon" src="assets/icons/Grupo%2063.svg" alt="">
                <div class="circle-hide">
                    <p class="show-m">VER MÁS</p>
                </div>
            </div>
            <div class="pt-4">
                <h5 class="text-center">Diseño <br> Web</h5>
            </div>
        </div>
    </div>
    <div class="techs  p-3">
        <div class="row">
            <div class="col-12 col-md-2 center">
                <h5>.Net</h5>
            </div>
            <div class="col-12 col-md-2 d-flex justify-content-md-end justify-content-center">
                <h5>Angular / ReactJs</h5>
            </div>
            <div class="col-12 col-md-4 center">
                <h5>Java</h5>
            </div>
            <div class="col-12 col-md-2 d-flex justify-content-md-start justify-content-center ">
                <h5>Android</h5>
            </div>
            <div class="col-12 col-md-2 center">
                <h5>iOS</h5>
            </div>
        </div>
    </div>
    <div class="clients">
        <div class="col-12 center">
            Nuestros Clientes:
        </div>
        <div class="logos row ">
            <img class="imc-cli col-12 col-md-4 mt-3 mt-md-0" src="assets/logo/echale_logo.svg" alt="">
            <img class="imc-cli col-12 col-md-4" src="assets/logo/red%20ring_logo.svg" alt="">
            <img class="imc-cli col-12 col-md-4 mt-3 mt-md-0" src="assets/logo/scorce_logo.svg" alt="">
        </div>
    </div>
    <div class="row contact p-md-5">
        <div class="col-12 col-md-6">
            <h2>Contacto</h2> <br>
            <h5>Pide hoy una cotización para tu solucion tecnologica <br> ¿Tienes alguna duda o comentario? <br> Escribenos para comenzar a solucionar en grande <br></h5>
            <h3><br> Tu pones el problema, <br> nosotros la solución. <br><br><br>
                <mat-icon class="mr-4" style="font-size: 2rem"><a target="_blank" href="https://www.facebook.com/MXSSconsultora" style="text-decoration: none; color: #fff;">facebook</a></mat-icon>
            </h3>
        </div>
        <div class="col-12 col-md-6">
            <form>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Nombre">
                </div>
                <div class="form-group">
                    <input type="email" class="form-control" placeholder="Correo">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Asunto">
                </div>
                <div class="form-group">
                    <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Mensaje" rows="5"></textarea>
                </div>
            </form>
            <div class="center col-12 pb-3">
                <button class="send-btn">Enviar</button>
            </div>
        </div>
    </div>
</div>