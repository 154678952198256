import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {IndexComponent} from './pages/index/index.component';
import {ConsultingComponent} from './pages/consulting/consulting.component';
import {SoftwareComponent} from './pages/software/software.component';
import {MovAppComponent} from './pages/mov-app/mov-app.component';
import {WebDesignComponent} from './pages/web-design/web-design.component';

const routes: Routes = [
  {path: 'index', component: IndexComponent},
  {path: 'consulting', component: ConsultingComponent},
  {path: 'software', component: SoftwareComponent},
  {path: 'app', component: MovAppComponent},
  {path: 'web', component: WebDesignComponent},
  {path: '', redirectTo: '/index', pathMatch: 'full'}

];

@NgModule({  
  imports: [RouterModule.forRoot(routes, {useHash: true, scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
