<div class="">
  <div class="row">
    <div class="center col-12 p-4">
      <h2 style="color: white">Aplicaciones móviles para la optimización de tu negocio.</h2>
    </div>
    <div class="col-12 col-md-7 white-background p-5">
      <h2>
        Aplicaciones moviles <br><br>
      </h2>
      <p class="txt">
        Desarrollamos aplicaciones móviles a medida para optimizar tus actividades de negocio, ya sea
        para gestión, control o integración con tu software; nos adaptamos a tus necesidades. <br><br>
        Nuestros desarrollos son
        para los sistemas Android y iOS.
      </p>

    </div>
    <div class="col-12 col-md-5 p-0">
      <img class="w-100 h-100" src="assets/img/imagen%20app.png" alt="app-img">
    </div>
  </div>
  <div class="blue-background-op  row">
    <div class="col-12 center p-5">
      <h2 style="color: white;">¿Cuáles son las ventajas de tener una App?</h2>
    </div>
    <div class="col-12 txt-white col-md-6">
      <div class="row p-3">
        <div class="col-1 col-sm-1 center ">
          <mat-icon>check</mat-icon>
        </div>
        <div class="col-11 col-sm-11">
          <p>
            Mejor integración con tu negocio.
          </p>
        </div>
      </div>
      <br>
      <div class="row p-3">
        <div class="col-1 center">
          <mat-icon>check</mat-icon>
        </div>
        <div class="col-11">
          <p>Sistemas iOS y Android</p>
        </div>
      </div>
      <br>
      <div class="row p-3">
        <div class="col-1 center">
          <mat-icon>check</mat-icon>
        </div>
        <div class="col-11">
          <p>Mayor atracción de clientes y usuarios.</p>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 txt-white">
      <div class="row p-3">
        <div class="col-1 center">
          <mat-icon>check</mat-icon>
        </div>
        <div class="col-11">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore</p>
        </div>
      </div>
      <div class="row p-3">
        <div class="col-1 center">
          <mat-icon>check</mat-icon>
        </div>
        <div class="col-11">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore</p>
        </div>
      </div>
      <div class="row p-3">
        <div class="col-1 center">
          <mat-icon>check</mat-icon>
        </div>
        <div class="col-11">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore</p>
        </div>
      </div>

    </div>
  </div>
  <div class="row contact">
    <div class="col-12 center p-3">
      <h4 style="color: white">
        ¿Tu negocio está listo para dar el siguiente paso?
      </h4>
    </div>
    <div class="col-12 center pt-3 pb-4">
      <button class="btn btn-contact">Envíanos tus dudas</button>
    </div>
  </div>
  <div class="row p-3">
    <div class="col-12 center p-5">
      <h4 style="color: white">Además, conoce otras soluciones para tu negocio:</h4>
    </div>
    <div class="col-md-4 flex-column center"  routerLink="/consulting" style="cursor: pointer">
      <div class="circle">
        <img class="icon" src="assets/icons/Grupo%2060.svg" alt="">
        <div class="circle-hide">
          <p style="font-size: 1rem" class="show-m">VER MÁS</p>
        </div>
      </div>
      <div class="pt-4">
        <h5 class="text-center">Consultoría y <br> asesoramiento</h5>
      </div>
    </div>
    <div class="col-md-4 flex-column center"  routerLink="/software" style="cursor: pointer">
      <div class="circle">
        <img class="icon" src="assets/icons/Grupo%2049.svg" alt="">
        <div class="circle-hide">
          <p style="font-size: 1rem" class="show-m">VER MÁS</p>
        </div>
      </div>
      <div class="pt-4">
        <h5 class="text-center">Desarrollo de <br> Software</h5>
      </div>
    </div>
    <div class="col-md-4 flex-column center"  routerLink="/web" style="cursor: pointer">
      <div class="circle">
        <img class="icon" src="assets/icons/Grupo%2063.svg" alt="">
        <div class="circle-hide">
          <p style="font-size: 1rem" class="show-m">VER MÁS</p>
        </div>
      </div>
      <div class="pt-4">
        <h5 class="text-center">Diseño <br> Web</h5>
      </div>
    </div>
  </div>
  <div class="center col-12 p-5">
    <img src="assets/logo/mxss%20logo-1.svg" alt="mxss-logo">
  </div>
</div>
