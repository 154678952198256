<div class="">
  <div class="row">
    <div class="col-12 col-md-7 white-background p-md-5">
      <h2>
        Desarrollo de Software <br>
      </h2>
      <h3>
        Un traje a la medida para tu empresa. <br><br></h3>
      <p class="txt">Actualmente existem muchas opciones en software que pueden
        solucionar la problemática que tenemos, sin embargo cuando las necesidades son muy específicas, lo más
        recomendable es crear
        un software específicamente diseñado a las necesidades de la empresa, es decir un software a medida. <br><br>
        Este tipo de desarrollo que es especialmente diseñado a las necesidades de cada cliente, requiere de una gran
        inversión en tiempo y recursos, ya que se analizan detalladamente cada requerimiento y proceso para
        posteriormente llevarlo a una línea de código. <br><br>
        Nuestro compromiso es desarrollar sistemas sólidos que puedan crecer a futuro con el
        crecimiento del negocio, permitiendo que la inversión en un sistema a medida sea rentable y alcanzable.</p>
    </div>
    <div class="col-12 col-md-5 p-0">
      <img class="w-100 h-100" src="assets/img/imagen%20software.png" alt="software">
    </div>
  </div>
  <div class="col-12 blue-background center p-4">
    <h2 style="color: white">
      Sistemas a medida para escritorio, móviles y páginas web
    </h2>
  </div>
  <div class="row">
    <div class="col-md-4 blue-background-op p-4">
      <p style="color: white">
        <span class="center bold">Escritorio</span> <br> Sistemas que se adaptan a la infraestructura del cliente.
        Nuestro equipo está capacitado para
        desarrollar proyectos con tecnologías .NET y JAVA. Además de los procesos propios de la programación, dominamos
        el uso y adaptación de Frameworks como AMAP, Hibernate, Ibatis, Struts, Spring, Spring-WS, Jaxb, Wss4j, Log4j,
        IText, IReports, Dwr.
      </p>
    </div>
    <div class="col-md-4 white-background-op p-4">
      <p style="color: #2D519F"><span class="center bold">Móvil</span> <br> Si el sistema lo requiere, podemos
        complementar tu software con la implementación de dispositivos
        móviles. Desarrollamos los sistemas que el software necesita en sus versiones para iOS y Android.</p>
    </div>
    <div class="col-md-4 blue-background-op p-4">
      <p style="color: white">
        <span class="center bold">Web</span> <br> Desarrollamos sistemas Web que puedan vincularse con tu sitio. Estos
        sistemas a medida, se adaptan a un
        diseño visual ya establecido y se construyen con tecnología .NET y Angular. También desarrollamos tiendas
        virtuales y portales de gestión de contenidos.
      </p>
    </div>
  </div>
  <div style="color: #2D519F !important;" class="row white-background">
    <div class="col-12 center p-3">
      <h2>¿Cuáles son las ventajas de tener un software a medida?</h2>
    </div>
    <div class="col-12 col-md-6">
      <div class="row p-3">
        <div class="col-1 center ">
          <mat-icon>check</mat-icon>
        </div>
        <div class="col-11">
          <p>
            Un software único, que este diseñado para las necesidades específicas que requiere mi negocio
          </p>
        </div>
      </div>
      <div class="row p-3">
        <div class="col-1 center">
          <mat-icon>check</mat-icon>
        </div>
        <div class="col-11">
          <p>Mayor control de procesos, al automatizarlos y </p>
        </div>

      </div>
      <div class="row p-3">
        <div class="col-1 center">
          <mat-icon>check</mat-icon>
        </div>
        <div class="col-11">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolor.e </p>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 pb-5">
      <div class="row p-3">
        <div class="col-1 center">
          <mat-icon>check</mat-icon>
        </div>
        <div class="col-11">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore </p>
        </div>
      </div>
      <div class="row p-3">
        <div class="col-1 center">
          <mat-icon>check</mat-icon>
        </div>
        <div class="col-11">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore</p>
        </div>
      </div>
      <div class="row p-3">
        <div class="col-1 center">
          <mat-icon>check</mat-icon>
        </div>
        <div class="col-11">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore</p>
        </div>
      </div>

    </div>
  </div>
  <div class="row contact">
    <div class="col-12 center p-3">
      <h4 style="color: white">
        ¿Tu negocio está listo para dar el siguiente paso?
      </h4>
    </div>
    <div class="col-12 center pt-3 pb-4">
      <button class="btn btn-contact">Envíanos tus dudas</button>
    </div>
  </div>
  <div class="row p-3">
    <div class="col-12 center p-5">
      <h4 style="color: white">Además, conoce otras soluciones para tu negocio:</h4>
    </div>
    <div class="col-md-4 flex-column center"  routerLink="/consulting" style="cursor: pointer">
      <div class="circle">
        <img class="icon" src="assets/icons/Grupo%2060.svg" alt="">
        <div class="circle-hide">
          <p style="font-size: 1rem" class="show-m">VER MÁS</p>
        </div>
      </div>
      <div class="pt-4">
        <h5 class="text-center">Consultoría y <br> asesoramiento</h5>
      </div>
    </div>
    <div class="col-md-4 flex-column center"  routerLink="/app" style="cursor: pointer">
      <div class="circle">
        <img class="icon" src="assets/icons/Grupo%2052.svg" alt="">
        <div class="circle-hide">
          <p style="font-size: 1rem" class="show-m">VER MÁS</p>
        </div>
      </div>
      <div class="pt-4">
        <h5 class="text-center">Aplicaciones <br> móviles</h5>
      </div>
    </div>
    <div class="col-md-4 flex-column center"  routerLink="/web" style="cursor: pointer">
      <div class="circle">
        <img class="icon" src="assets/icons/Grupo%2063.svg" alt="">
        <div class="circle-hide">
          <p style="font-size: 1rem" class="show-m">VER MÁS</p>
        </div>
      </div>
      <div class="pt-4">
        <h5 class="text-center">Diseño <br> Web</h5>
      </div>
    </div>
  </div>
  <div class="center col-12 p-5">
    <img src="assets/logo/mxss%20logo-1.svg" alt="mxss-logo">
  </div>
</div>

