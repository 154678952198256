<div class="center p-4">
  <h2 style="color: white">Asesoría personalizada para encontrar la mejor solución tecnológica para tu negocio.</h2>
</div>
<div class="consulting row">
  <div class="col-12 col-md-7 p-5">
    <h2>
      Consultoría y asesoramiento <br><br>
    </h2>
    <p class="txt">Antes de ofrecer un sistema, debemos primero entender cual es la
      solución que va a ofrecer
      ese sistema. Muchas veces nuestro negocio requiere de una herramienta que facilite algún proceso pero no sabemos
      la
      magnitud de lo que eso requiere. <br><br>
      Mediante una consultoría en tecnología, podemos explorar las diferentes posibilidades
      que tu negocio requiere para comprender lo que realmente se necesita. <br><br>
      Realizamos asesoría personalizada para cada
      cliente en las diferentes alternativas y posibilidades en informática y tecnología para su negocio, permitiendo
      que
      los resultados favorezcan a su empresa y en sus resultados.</p>

  </div>
  <div class="col-12 col-md-5 p-0">
    <img class="w-100 h-100" src="assets/img/imagen%20consultoria.png" alt="consult">
  </div>
</div>
<div class="advantage row">
  <div class=" col-12 center">
    <h2 style="color: white" class="p-3">¿Cuáles son las ventajas de realizar una consultoría tecnológica?</h2>
  </div>
  <div class="col-12 col-md-6">
    <div class="row p-3">
      <div class="col-1 center ">
        <mat-icon>check</mat-icon>
      </div>
      <div class="col-11">
        <p style="font-size: 1.2rem">
          Optimizar recursos al conocer los alcances de una solución tecnológica.
        </p>
      </div>
    </div>
    <div class="row p-3">
      <div class="col-1 center">
        <mat-icon>check</mat-icon>
      </div>
      <div class="col-11">
        <p style="font-size: 1.2rem">Conocer los alcances de mi negocio y permitir encontrar una solución tecnológica
          que agilice procesos y tiempos de
          respuesta.</p>
      </div>

    </div>
    <div class="row p-3">
      <div class="col-1 center">
        <mat-icon>check</mat-icon>
      </div>
      <div class="col-11">
        <p style="font-size: 1.2rem">Permite pensar en grande, al ser un proceso de revisión se puede crear una
          estrategia de crecimiento a corto,
          mediano y largo plazo. </p>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6">
    <div class="row p-3">
      <div class="col-1 center">
        <mat-icon>check</mat-icon>
      </div>
      <div class="col-11">
        <p style="font-size: 1.2rem">Despejar tus dudas sobre las alternativas tecnológicas que pueden ayudar a mejorar
          mi negocio </p>
      </div>
    </div>
    <div class="row p-3">
      <div class="col-1 center">
        <mat-icon>check</mat-icon>
      </div>
      <div class="col-11">
        <p style="font-size: 1.2rem">Explorar diferentes alternativas</p>
      </div>
    </div>
    <div class="row p-3">
      <div class="col-1 center">
        <mat-icon>check</mat-icon>
      </div>
      <div class="col-11">
        <p style="font-size: 1.2rem">Crear una estrategia de crecimiento</p>
      </div>
    </div>

  </div>
</div>
<div class="row contact">
  <div class="col-12 center p-3">
    <h4 style="color: white">
      ¿Tu negocio está listo para dar el siguiente paso?
    </h4>
  </div>
  <div class="col-12 center pt-3 pb-4">
    <button class="btn btn-contact">Envíanos tus dudas</button>
  </div>
</div>
<div class="row p-3">
  <div class="col-12 center p-5">
    <h4 style="color: white">Además, conoce otras soluciones para tu negocio:</h4>
  </div>
  <div class="col-md-4 flex-column center"  routerLink="/software" style="cursor: pointer">
    <div class="circle">
      <img class="icon" src="assets/icons/Grupo%2049.svg" alt="">
      <div class="circle-hide">
        <p class="show-m">VER MÁS</p>
      </div>
    </div>
    <div class="pt-4">
      <h5 class="text-center">Desarrollo <br> de Software</h5>
    </div>
  </div>
  <div class="col-md-4 flex-column center"  routerLink="/app" style="cursor: pointer">
    <div class="circle">
      <img class="icon" src="assets/icons/Grupo%2052.svg" alt="">
      <div class="circle-hide">
        <p class="show-m">VER MÁS</p>
      </div>
    </div>
    <div class="pt-4">
      <h5 class="text-center">Aplicaciones <br> móviles</h5>
    </div>
  </div>
  <div class="col-md-4 flex-column center"  routerLink="/web" style="cursor: pointer">
    <div class="circle">
      <img class="icon" src="assets/icons/Grupo%2063.svg" alt="">
      <div class="circle-hide">
        <p class="show-m">VER MÁS</p>
      </div>
    </div>
    <div class="pt-4">
      <h5 class="text-center">Diseño <br> Web</h5>
    </div>
  </div>
</div>
<div class="center col-12 p-5">
  <img src="assets/logo/mxss%20logo-1.svg" alt="mxss-logo">
</div>
